
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    CrudMultiSelect,
  },
})
export default class JobSelector extends Vue {
  @Prop({ default: null }) value!: any;
}
