
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CondOperator, SFields } from '@dataui/crud-request';
import { UserRoleEnum } from '@gid/models';

@Component({
  components: {
    CrudMultiSelect,
  },
})
export default class AccountSelector extends Vue {
  @Prop({ default: [] }) roles!: UserRoleEnum[];
  @Prop({ default: null }) value!: any;

  get queryParams() {
    const rolesSearch: SFields[] = [];
    this.roles.forEach((role) => {
      if (
        [
          UserRoleEnum.PARTNER,
          UserRoleEnum.BRAND,
          UserRoleEnum.CUSTOMER,
        ].includes(role)
      ) {
        rolesSearch.push({ role: { [CondOperator.EQUALS]: role } });
      }
    });
    return {
      fields: ['id', 'name'],
      search: [{ $or: rolesSearch }],
    };
  }
}
